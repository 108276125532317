import Page from "../components/page";
import PropTypes from "prop-types";
import React from "react";
import SEO from "../components/seo";
import { graphql } from "gatsby";

const Links = ({ data }) => {
  let delay = 0;
  const linksList = data.links.edges.map(link => {
    delay += 50;
    return (
      <a
        key={link.node.id}
        className='block mb-6 sm:mb-8 sm:mr-10'
        href={link.node.url}
        rel='noopener noreferrer'
        target='_blank'
        data-aos='fade-up'
        data-aos-delay={delay}
      >
        {link.node.image ? 
          <div className='sm:h-32 flex items-center'>
            <img
              className='max-h-full'
              loading='lazy'
              src={link.node.image.fluid.src}
              alt={link.node.name}
              title={link.node.name}
            />
          </div>
          : 
          <span className='flex-1 text-secondary hover:text-blue-600 transition-color underline'>
            {link.node.name}
          </span>
        }
      </a>
    );
  });

  return (
    <Page>
      <SEO title='Links' />
      <div className='container px-8 mb-12 lg:mb-24 mt-4 lg:mt-16'>
        <h2 className='title mb-6'>Links</h2>
        <div className='wysiwyg'>
          <p>
            Hieronder vindt u enkele links naar praktijken met prioritaire
            samenwerking, beroepsverenigingen & achtergrond informatie
          </p>
        </div>
        <div className='flex items-center flex-wrap justify-start mt-6'>
          {linksList}
        </div>
      </div>
    </Page>
  );
};

Links.propTypes = {
  data: PropTypes.object.isRequired
};

export default Links;

export const pageQuery = graphql`
  query {
    links: allContentfulLink(sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          id
          createdAt
          name
          url
          image {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;
